<template>
  <HeaderAndNav></HeaderAndNav>
  <section class="mainContainer">
    <div class="lineContainer">
      <svg
        data-direction="vertical"
        viewBox="0 0 92 1024"
        fill="none"
        preserveAspectRatio="xMidYMax meet"
      >
        <path
          d="M23.5 0.5V338.786L1.5 350.5L1 374L23 385.5L44 373.5L23.5 362L44 350.5L23 338.5L2 350.234L22.5 362L1 373L2 374.767L22.5 384.5L23.5 387.5L24.5 527.5L24.25 546.75L24.5 630.875L23.5 625.875L14.5 620.875L4.5 626.375V636.875L14 642.875L33.5 631.875L43 637.375V648.375L34 654.375L24.5 648.875V643.375V1378.5"
          :stroke="lineColor"
          stroke-width="1"
        />
      </svg>
    </div>

    <article class="sectionOne standardArticle">
      <aside>
        <h1>Community Driven DeFi Platform</h1>
        <p>
          Lendr is a decentralized reserve currency protocol available on the
          Polygon Network based on the Lendr token. <br /><br />Each Lendr token
          is backed by a basket of assets (Dai, Matic, wBTC and wEth) in the
          Lendr treasury, giving it an intrinsic value that cannot fall below a
          certain amount.
        </p>
        <a class="button" href="#">Coming Soon</a>
      </aside>
      <figure>
        <LottieCustom
          :speed="1"
          class="lottieAnimation"
          ref="anim"
          :animationData="require(`@/assets/floating_laptop.json`)"
        ></LottieCustom>
      </figure>
    </article>
    <article class="sectionTwo standardArticle">
      <figure ref="anim2">
        <LottieCustom
          :speed="1"
          class="lottieAnimation"
          :animationData="require(`@/assets/blockchain_server.json`)"
          :loop="false"
          v-if="isAnimTwoVisible"
        ></LottieCustom>
      </figure>
      <aside>
        <h1>Simplifiying Defi-economic's with gameification</h1>
        <p>
          Lendr introduces Defi-economic's with gameification where you can
          peform staking and minting through daily and weekly quests. Quest
          completion yields Efflux that can be used to buy limited edition
          NFT's. Our goal is to provide a self dependent platform that gives
          everyone access to the basics in a decentralized environment all
          powered by smart contracts on the Polygon Network.
        </p>
        <a class="button" href="https://docs.lendr.finance" target="_blank">
          <p class="hiddenText">read about lendr in the docs here:</p>
          Learn more
          <Icon icon="charm:link-external" />
        </a>
      </aside>
    </article>
    <article class="sectionThree standardArticle">
      <aside>
        <h1>Built on the Polygon Chain</h1>
        <p>
          Polygon combines the best of Ethereum and sovereign blockchains into a
          full-fledged multi-chain system.
        </p>
        <p>
          Polygon solves pain points associated with Blockchains, like high gas
          fees and slow speeds, without sacrificing on security. This
          multi-chain system is akin to other ones such as Polkadot, Cosmos,
          Avalanche etc, but with at least three major upsides:
        </p>
        <p>
          It is able to fully benefit from Ethereum's network effects
          <Icon icon="akar-icons:check" />
          <br />
          It is inherently more secure
          <Icon icon="akar-icons:check" />
          <br />
          It is more open and powerful
          <Icon icon="akar-icons:check" />
        </p>
        <a class="button" href="https://polygon.technology" target="_blank">
          <p class="hiddenText">read about polygon on their homepage here:</p>
          Learn more
          <Icon icon="charm:link-external" />
        </a>
      </aside>
      <figure ref="anim3">
        <LottieCustom
          :speed="1"
          class="lottieAnimation"
          :animationData="require(`@/assets/servers.json`)"
          :loop="false"
          v-if="isAnimThreeVisible"
        ></LottieCustom>
      </figure>
    </article>
    <Footer></Footer>
  </section>
</template>

<script>
/* eslint-disable no-unused-vars */
import { defineAsyncComponent } from "@vue/runtime-core";
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";
import { Icon } from "@iconify/vue";
export default {
  name: "App",
  data() {
    return {
      isAnimTwoVisible: false,
      isAnimThreeVisible: false,
      lineColor: "#0b81f5",
    };
  },
  setup() {
    const anim2 = ref(null);
    const anim2IsVisible = useElementVisibility(anim2);
    const anim3 = ref(null);
    const anim3IsVisible = useElementVisibility(anim3);
    return {
      anim2,
      anim2IsVisible,
      anim3,
      anim3IsVisible,
    };
  },
  components: {
    LottieCustom: defineAsyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "LottieCustom" */ "./components/LottieCustom.vue"
        ),
    }),
    HeaderAndNav: defineAsyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "HeaderAndNav"*/ "./components/HeaderAndNav.vue"
        ),
    }),
    Footer: defineAsyncComponent({
      loader: () =>
        import(/* webpackChunkName: "Footer"*/ "./components/Footer.vue"),
    }),
    Icon,
  },
  watch: {
    anim2IsVisible() {
      this.isAnimTwoVisible = true;
    },
    anim3IsVisible() {
      this.isAnimThreeVisible = true;
    },
  },
  mounted() {
    let path = document.querySelector("path");
    let pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength + " " + pathLength;
    path.style.strokeDashoffset = pathLength;

    window.addEventListener("scroll", () => {
      var scrollPercentage =
        (document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight);
      var drawLength = pathLength * scrollPercentage;
      path.style.strokeDashoffset = pathLength - drawLength;
      if (scrollPercentage < 0.2) {
        this.lineColor = "#fff";
      } else if (scrollPercentage > 0.5 && scrollPercentage < 0.83) {
        this.lineColor = "#8248e5";
      } else {
        this.lineColor = "#0b81f5";
      }
    });
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
body {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("./assets/endless-constellation.svg");
  margin: 0;
  *::-webkit-scrollbar-track {
    background: var(--background);
    display: none;
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    border: 3px solid var(--primary);
    display: none;
  }
  *::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
}
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background: var(--background);
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(59, 59, 73);
  border-radius: 20px;
  border: 0px solid var(--background);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  button {
    all: unset;
    color: var(--textColor);
    background-color: var(--secondary);
    padding: 8px 25px;
    font-size: 1.2em;
    border-radius: 2em;
    text-align: center;
    width: max-content;
    transition: all 0.3s;
    svg {
      margin-bottom: -3px;
    }
  }
  button:hover {
    cursor: pointer;
    background-color: var(--hover) !important;
  }
  .button {
    all: unset;
    color: var(--textColor);
    background-color: var(--secondary);
    padding: 8px 25px;
    font-size: 1.2em;
    border-radius: 2em;
    text-align: center;
    width: max-content;
    transition: all 0.3s;
    svg {
      margin-bottom: -3px;
    }
  }
  .button:hover {
    cursor: pointer;
    background-color: var(--hover) !important;
  }
  .mainContainer {
    display: flex;
    max-width: 1500px;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 3000px;
    @media only screen and (max-width: 1200px) {
      max-width: 800px;
      height: auto;
    }
    .lineContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2670px;
      text-align: center;
      overflow: hidden;
      z-index: 1;
      svg {
        display: inline-block;
        height: 100%;
        width: 100%;
        margin-left: 60px;
        filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
      }
      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
    p {
      color: var(--textColor);
      letter-spacing: 1px;
      line-height: 20px;
      font-size: 1em;
      opacity: 0.9;
    }
    h1 {
      color: var(--textColor);
      font-size: 3.5em;
      opacity: 0.9;
    }
    h2 {
      color: var(--textColor);
      font-size: 1.5em;
      opacity: 0.9;
    }

    .standardArticle {
      display: flex;
      gap: 100px;

      align-items: center;
      justify-content: center;
      margin-top: 250px;
      height: 500px;
      z-index: 2;
      .hiddenText {
        opacity: 0;
        position: absolute;
        top: -20px;
      }
      aside {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 40%;
      }
      figure {
        width: 40%;
        min-height: 420px;
        .lottieAnimation {
          animation: fadeIn 0.5s ease;
        }
      }
      a {
        z-index: 2 !important;
      }
      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        margin-top: 100px;
        height: auto;
        aside {
          width: 90%;
          text-align: left;
          align-items: flex-start !important;
        }
        @media only screen and (max-width: 800px) {
          gap: 30px;
          margin-top: 100px;
          h1 {
            font-size: 2em;
          }
          figure {
            margin-top: 20px;
          }
          @media only screen and (max-width: 800px) {
            margin-bottom: -80px;
          }
        }
        figure {
          width: 50%;
          @media only screen and (max-width: 800px) {
            width: 90%;
            min-height: 510px;
            svg {
              max-height: 500px;
            }
          }
        }
      }
    }
    .sectionOne {
      text-align: right;
      figure {
        filter: hue-rotate(350deg) saturate(123%);
        overflow: hidden;
        .lottieAnimation {
          width: 130%;
          margin-left: -15%;
        }
      }
      aside {
        align-items: flex-end;
      }
      .button {
        opacity: 0.5;
      }
      //TODO remove for prod
      .button:hover {
        background-color: var(--secondary) !important;
        cursor: auto;
      }
    }
    .sectionTwo {
      text-align: left;
      .lottieAnimation {
        max-width: 500px;
      }
      figure {
        min-height: 610px;
      }
      h1 {
        color: var(--secondary);
      }
      @media only screen and (max-width: 1200px) {
        flex-direction: column-reverse;
        margin-top: 0px;
        aside {
          margin-bottom: -150px;
        }
      }
    }
    .sectionThree {
      text-align: right;
      .lottieAnimation {
        max-width: 500px;
      }
      figure {
        min-height: 410px;
      }
      h1 {
        color: var(--primaryTwo);
      }
      aside {
        align-items: flex-end;
        .button {
          background-color: var(--primaryTwo);
          svg {
            color: var(--textColor);
          }
        }
        svg {
          width: 20px;
          height: 20px;
          margin-bottom: -3px;
          color: var(--primaryTwo);
        }
      }
      @media only screen and (max-width: 1200px) {
        margin-top: 0px;
      }
    }
  }
}
[data-theme="appDark"] {
  --borderColor: #2b2e3a;
  --textColor: #fff;
  --primary: #1c1e26;
  --primaryTwo: #8248e5;
  --mainBlue: #0b81f5;
  --secondary: #0a74dd;
  --background: #081930;
  --hover: #2b2e3a;
  --accent: #50ebe8;
  --backgroundTwo: #093059;
  --shadow: 0px 0px 0px rgba(255, 255, 255, 0);
  --lightTransparent: rgba(8, 25, 48, 0.01);
}
[data-theme="appLight"] {
  --borderColor: rgb(215, 215, 215);
  --textColor: #050505;
  --primary: #fff;
  --primaryTwo: #eec0c6;
  --mainBlue: #0b81f5;
  --secondary: #50ebe8;
  --background: #d4e8ff;
  --hover: rgb(245, 245, 245);
  --accent: #1b20d5;
  --backgroundTwo: #eeaeca;
  --shadow: 0px 0px 6px rgba(22, 22, 22, 0.25);
  --lightTransparent: rgba(255, 255, 255, 0.01);
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
